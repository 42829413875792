@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: sans-serif;
  user-select: none;
  border-style: solid;
}

button,
a {
  cursor: pointer !important;
}

html {
  overflow-x: hidden;
  overflow-y: overlay;
}

html body {
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  padding: 0;
  margin: 0;
  scrollbar-width: none;
  background: #010101;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
label,
a,
button,
p,
button {
  font-family: "Nunito", sans-serif;
}

ul {
  list-style: none;
}
